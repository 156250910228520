import './Slider.scss'
import '../../Global.css'
import { motion } from 'framer-motion'

const Slider = () => {


    const SliderVAR = {
        hidden:{
            opacity: 0,
        },
        visible:{
                opacity: 1,
                transition:{
                duration: 1,
                delay: .5,
                when: 'beforeChildren',
                staggerChildren: .1,

            }
        }
    }

  
    

    
    return ( 
        <motion.main className='Slider'
        variants={SliderVAR}
        initial="hidden"
        animate="visible"
        >
            <div className="container relative">
                <div className="box absolute">
                </div>
            </div>
        </motion.main>
     );
}
 
export default Slider;