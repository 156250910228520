import './Line.sass'


const Line = () => {
    return ( 
        <main className="Line">
            <div className="container">
                <span className="line"></span>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Officiis minima quo accusamus ratione?</p>
                <span className="line"></span>
            </div>
        </main>
     );
}
 
export default Line;
