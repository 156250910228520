import './Header.sass'


const Header = ({titleA, titleB}) => {
    return ( 
        <main className="Header">
            <h1><span style={{color: 'white'}}>{titleA}</span> <span style={{color: 'orange'}}>{titleB}</span></h1>
        </main>
     );
}
 
export default Header;
