import { Link } from 'react-router-dom';
import './Footer.sass'
import { AiOutlineMail } from 'react-icons/ai'
import { BiPhoneCall } from 'react-icons/bi'
import { BsFacebook } from 'react-icons/bs'
import { BsYoutube } from 'react-icons/bs'
import { BsInstagram } from 'react-icons/bs'
import { BsTwitter } from 'react-icons/bs'




const Footer = () => {
    return ( 
        <main className="Footer">
            <div className="cover">
                <div className='cont'>
                    <p style={{fontSize: '30px'}}>Brixton</p>
                    <p style={{fontSize: '17px'}}>We are Brixton, get your earnings to the next level in no time</p>
                    <p style={{fontSize: '17px'}}>4730 Rocky Road California, CA 19010</p>
                </div>
                <div className='cont'>
                    <p>Pages</p>
                    <ul>
                        <li><Link className='hoverunderline hoverblue'>Homepage</Link></li>
                        <li><Link className='hoverunderline hoverblue'>Blog & News</Link></li>
                        <li><Link className='hoverunderline hoverblue'>About Us</Link></li>
                        <li><Link className='hoverunderline hoverblue'>Homepage</Link></li>
                    </ul>
                </div>
                <div className='cont'>
                    <p>Support</p>
                    <ul>
                        <li><Link className='hoverunderline hoverblue'>FAQ's</Link></li>
                        <li><Link className='hoverunderline hoverblue'>Support Center</Link></li>
                        <li><Link className='hoverunderline hoverblue'>Security</Link></li>
                    </ul>
                </div>
                <div className='cont'>
                    <p>Contact</p>
                    <div className='social'>
                        <BsFacebook className='pointer hoverred'  style={{fontSize: '20px'}}/>
                        <BsInstagram className='pointer hoverred'  style={{fontSize: '20px'}}/>
                        <BsYoutube className='pointer hoverred'  style={{fontSize: '20px'}}/>
                        <BsTwitter className='pointer hoverred'  style={{fontSize: '20px'}}/>
                        
                    </div>
                    <div className='wrapper'>
                        <BiPhoneCall style={{fontSize: '20px'}}/>
                        <p className='pointer hoverunderline'>+1 230 656 1234</p>
                    </div>
                    <div className='wrapper'>
                        <AiOutlineMail style={{fontSize: '20px', color: 'white'}}/>
                        <p className='pointer hoverunderline' style={{color: 'white'}}>info@brixton.com</p>
                    </div>
                </div>
            </div>
            <div className="copy">
                Copyright 2021 by Brixton. All Rights Reserved
            </div>
        </main>
     );
}
 
export default Footer;
