import '../../Global.css'
import {HiOutlineMenuAlt2} from 'react-icons/hi'
import './Card.scss'
import { motion, useSpring } from 'framer-motion'
import {BsCheckCircle} from 'react-icons/bs'
import {HiHeart} from 'react-icons/hi'
import {GiQueenCrown} from 'react-icons/gi'
import {BsCreditCard} from 'react-icons/bs'
import {HiDatabase} from 'react-icons/hi'
import {BsArrowRight} from 'react-icons/bs'
import Header from '../Header/Header'

const Card = () => {

    const infos = [
        {
            plan: 'Silver Plan',
            percent: '30%',
            duration: '20 days investment',
            minimum: '$2,000',
            maximum: '$5,000',
            commission: '7%',
            img: <HiHeart />
        },
        {
            plan: 'Gold Plan',
            percent: '40%',
            duration: '20 days investment',
            minimum: '$5,500',
            maximum: '$10,500',
            commission: '7%',
            img: <GiQueenCrown />
        },
        {
            plan: 'Platinum Plan',
            percent: '45%',
            duration: '20 days investment',
            minimum: '$11,000',
            maximum: '$15,000',
            commission: '7%',
            img: <BsCreditCard />
        },
        {
            plan: 'Diamond Plan',
            percent: '60%',
            duration: '20 days investment',
            minimum: '$15,500',
            maximum: '$20,000',
            commission: '7%',
            img: <HiDatabase />
        }
    ]

     return ( 
        <main className='Card'>
            <Header titleA='INVESTMENT' titleB='PLANS'/>
            <div className="cards">
                {infos.map((info, index)=>(
                    <motion.div className="card flex gap-1 flex-column pointer" whileHover={{scale: 1.05}}>
                        {info.img}
                        <p>{info.plan}</p>
                        <p>{info.percent}</p>
                        <p>{info.duration}</p>
                        <div className='flex flex-column camelcase gap-1 '>
                            <p className='flex gap-0p5'>
                                <BsCheckCircle style={{fontSize: '18px'}}/>
                                <p>Minimum Investment: {info.minimum}</p>
                            </p>
                            <p className='flex gap-0p5'>
                                <BsCheckCircle style={{fontSize: '18px'}}/>
                                <p>Maximum Investment: {info.maximum}</p>
                            </p>
                            <p className='flex gap-0p5'>
                                <BsCheckCircle style={{fontSize: '18px'}}/>
                                <p>Referral Commission: {info.commission}</p>
                            </p>
                            <p className='flex gap-0p5'>
                                <BsCheckCircle style={{fontSize: '18px'}}/>
                                <p>Instant Withdrawal</p>
                            </p>
                        </div>
                        <p className='w100 text-center flex flex-align-center gap-0p2 flex-justify-center pointer'><span>Choose Plan</span><BsArrowRight style={{fontSize: '15px'}}/></p>
                    </motion.div>
                ))}
                
            </div>
        </main>
     );
}
 
export default Card;