import './MainNav.scss'
import '../../../src/Global.css'

import {HiOutlineSearch} from 'react-icons/hi'
import {BiChevronDown} from 'react-icons/bi'
import {AiOutlineClose} from 'react-icons/ai'
import {HiOutlineMenuAlt2} from 'react-icons/hi'
import { motion } from 'framer-motion'

import { NavLink } from 'react-router-dom'
import { useRef, useState } from 'react'

const MainNav = () => {
        const searchRef = useRef()
        const slideRef = useRef()
    const [open, setOpen] = useState(true)

    const slideToggle = () => {
        searchRef.current.classList.toggle('slide')
    }
    const close = () => {
        searchRef.current.classList.remove('slide')
    }
    const slidemobile = () => {
        slideRef.current.classList.toggle('slidemobile')
        setOpen(!open)
    }

    const navVAR = {
        hidden:{
            opacity: 0,
        },
        visible:{
                opacity: 1,
                transition:{
                duration: 1,
                delay: .5,
            }
        }
    }

    return ( 
        <main className='Nav'>
            <motion.div className="nav"
            variants={navVAR}
            initial="hidden"
            animate="visible"
            >
                <div className="links flex flex-align-center flex-justify-center white six uppercase">
                    <p><NavLink to='/'>Home</NavLink></p>
                    <p><NavLink to='/about'>About Us</NavLink></p>
                    <p><NavLink to='/services'>Services</NavLink></p>
                    <p><NavLink to='/plans'>plans</NavLink></p>
                    <p><NavLink to='/faq'>FAQ</NavLink></p>
                    <p><NavLink to='/tos'>Terms of services</NavLink></p>
                    <p><NavLink to='/contact'>contact</NavLink></p>
                    <p className='flex pointer flex-align-center gap-0p1 hoverred'>
                        <span>Account</span>
                        <span><BiChevronDown /></span>
                    </p>
                    <HiOutlineSearch onClick={slideToggle}  className='search-icon hoverred pointer' />
                </div>
                <div className="search relative flex flex-align-center flex-justify-center " ref={searchRef}>
                    <input type="text" className='transparent text-center white' placeholder='Search Brixton'/>
                    <AiOutlineClose className='close absolute pointer hoverred' onClick={close}/>
                </div>
            </motion.div>

            <div className="mobilenav">
                <div id="top"></div>
                <div className="top white flex w100 fixed flex-align-center nine gap-0p5">
                    {open 
                    ? <HiOutlineMenuAlt2 className='pointer' onClick={slidemobile}/>
                    : <AiOutlineClose className='pointer' onClick={slidemobile}/>
                    }
                    <p className='uppercase red'>Brixton</p>
                </div>
                <div className="bottom fixed vw100 flex uppercase gap-1p5 flex-column flex-align-center" ref={slideRef}>
                    <div className='flex flex-align-center gap-0p2'>
                        <HiOutlineSearch style={{fontSize: '20px', color: 'white'}}/>
                        <input type="text" placeholder='Search Brixton' />
                    </div>
                    <p><NavLink to='/'>Home</NavLink></p>
                    <p><NavLink to='/about'>About Us</NavLink></p>
                    <p><NavLink to='/services'>Services</NavLink></p>
                    <p><NavLink to='/services'>Sign In / Sign Up</NavLink></p>
                    <p><NavLink to='/plans'>plans</NavLink></p>
                    <p><NavLink to='/faq'>FAQ</NavLink></p>
                    <p><NavLink to='/tos'>Terms of services</NavLink></p>
                    <p><NavLink to='/contact'>contact</NavLink></p>
                    <p className='flex pointer flex-align-center gap-0p1 hoverred none'>
                        <span>Account</span>
                        <span><BiChevronDown /></span>
                    </p>
                </div>
            </div>
        </main>
     );
}
 
export default MainNav;