import { NavLink } from 'react-router-dom';
import './Nav.scss'
import '../../../src/Global.css'
import { FiChevronDown } from 'react-icons/fi'
import { HiOutlineSearch } from 'react-icons/hi'
import { FiShoppingBag } from 'react-icons/fi'
import { motion } from 'framer-motion'
import { BsBag } from 'react-icons/bs'
import { BiLogIn } from 'react-icons/bi'
import { BsPlayFill } from 'react-icons/bs'
import { AiOutlineUserAdd } from 'react-icons/ai'


const Nav = () => {
    const navVAR = {
        hidden:{
            opacity: 0,
        },
        visible:{
                opacity: 1,
                transition:{
                duration: .5,
                delay: .1,
                when: 'beforeChildren',
                staggerChildren: .1,

            }
        }
    }

    const linkVAR = {
        hidden:{
            y: -80,
        },
        visible:{
            y: 0,
            transition:{
                type: 'spring',
                stiffness: 200,
            }
        }
    }

    return ( 
        <main>
            <motion.nav
            variants={navVAR}
            initial="hidden"
            animate="visible" className=''>
                <div className='left-nav flex white flex-align-center flex-justify-center'>
                    <motion.p className="logo red"
                    whileHover={{color: 'red', scale: 1.4}} style={{cursor: 'pointer'}}>BRIXTON</motion.p>
                </div>

                <div className='center-nav flex flex-align-center flex-justify-center white'>
                    <motion.div className="links flex text-center flex-align-center flex-justify-center">
                        <motion.p className='link' variants={linkVAR} whileHover={{scale:  1.5}} transition={{type: 'spring', stiffness: '500'}}>
                            <p>10,020,950 USD</p>
                            <p>Market Cap</p>
                        </motion.p>
                        <motion.p className='link' variants={linkVAR} whileHover={{scale:  1.5}}  transition={{type: 'spring', stiffness: '500'}}>
                            <p>+5.25%</p>
                            <p>24 Hour Price</p>
                        </motion.p>
                        <motion.p className='link' variants={linkVAR} whileHover={{scale:  1.5}}  transition={{type: 'spring', stiffness: '500'}}>
                            <p>111BTC</p>
                            <p>24 Hour Volume</p>
                        </motion.p>
                        <motion.p className='link' variants={linkVAR} whileHover={{scale:  1.5}}  transition={{type: 'spring', stiffness: '500'}}>
                            <p>600,545</p>
                            <p>Active Traders</p>
                        </motion.p>
                        <motion.p className='link' variants={linkVAR} whileHover={{scale:  1.5}}  transition={{type: 'spring', stiffness: '500'}}>
                            <p className='btc flex'>
                                <span>$20035.50</span>
                                <span><BsPlayFill style={{transform: 'rotate(1350deg)',fontSize:'15px', color: 'rgb(0, 255, 8)'}}/></span>
                            </p>
                            <p>Live Bitcoin Price</p>
                        </motion.p>
                    </motion.div>
                </div>

                <div className='right-nav flex flex-align-center flex-justify-center'>
                    <p className='flex'>
                      <BiLogIn />
                      <p>SIGN IN</p>  
                    </p>
                    <p className='flex'>
                      <AiOutlineUserAdd />
                      <p>SIGN UP</p>  
                    </p>
                </div>
            </motion.nav>
        </main>
     )
}
 
export default Nav;
    