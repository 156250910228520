import Nav from '../Components/Nav/Nav'
import MainNav from '../Components/MainNav/MainNav'
import Slider from '../Components/Slider/Slider';
import Quality from '../Components/Quality/Quality';
import Card from '../Components/Card/Card';
import Contact from '../Components/Contact/Contact';
import About from '../Components/About/About';
import Expert from '../Components/Expert/Expert';
import Started from '../Components/Started/Started';
import Stats from '../Components/Stats/Stats';
import Footer from '../Components/Footer/Footer';

const Home = () => {
    return ( 
        <main>
            <Nav />
            <MainNav />
            <Slider />
            <Quality />
            <About />
            <Card />
            <Stats />
            <Expert />
            <Started />
            <Contact />
            <Footer />
        </main>
     )
}
 
export default Home;