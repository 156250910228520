import './Quality.scss'
import '../../../src/Global.css'
import { AiOutlineEyeInvisible } from 'react-icons/ai'

const Quality = () => {
    return ( 
        <main className = 'Quality'>
            
        </main>
     );
}
 
export default Quality;