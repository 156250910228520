import './Started.sass'

const Started = () => {
    return ( 
        <main className="Started">
            <div className="container">
                <p className='header'>GET STARTED TODAY WITH BRIXTON</p>
                <p className='text'>Open an account for free and start trading bitcoin</p>
                <p className='button'>Register Now</p>
            </div>
        </main>
     );
}
 
export default Started;