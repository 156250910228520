import Header from '../Header/Header';
import './Stats.sass'

const Stats = () => {
    return ( 
        <main className='Stats'>
            <Header titleA='OUR COMPANY' titleB='STATISTICS'/>
            <div className="container">
                <div className='contents'>
                    <p className='wrapper'>
                        <p>40+</p>
                        <p>COUNTRIES</p>
                    </p>
                    <p className='wrapper'>
                        <p>$10 MILLION+</p>
                        <p>MONTHLY DEPOSITS</p>
                    </p>
                    <p className='wrapper'>
                        <p>$25 MILLION+</p>
                        <p>MONTHLY WITHDRAWALS</p>
                    </p>
                </div>
                <p className='btn-wrapper'>
                    <p>SEE PLANS</p>
                    <span>or</span>
                    <p>REGISTER NOW</p>
                </p>
            </div>
        </main>
     );
}
 
export default Stats;