import './About.sass'
import '../../Global.css'

import { MdLaptop } from 'react-icons/md'
import Header from '../Header/Header';
import Line from '../Line/Line';

const About = () => {
    return ( 
        <main className='About'>
            <Header titleA='ABOUT' titleB='US'/>
            <Line />
            <div className="container">
                <div className="left"></div>
                <div className="right">
                    <p className='header'>WE ARE BRIXTONTRADE.COM</p>
                    <p className='text1'>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Amet sunt doloremque ex commodi vel, necessitatibus eius rerum distinctio omnis doloribus cum tempore magni nostrum labore, quis rem totam dolorum dolores!</p>
                    <p className='wrapper'>
                        <p>OUR MISSION</p>
                        <p>OUR ADVANTAGES</p>
                        <p>OUR GUARANTEE</p>
                    </p>
                    <p className='text2'>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Natus dolor neque dolore ducimus? Quasi, tempora.</p>
                    <span className='button'>READ MORE</span>
                </div>
            </div>
        </main>
     );
}
 
export default About;
