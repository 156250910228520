import '../../Global.css'
import './Contact.scss'
import { motion } from 'framer-motion'
import {HiOutlineHome} from 'react-icons/hi'
import {MdLocationOn} from 'react-icons/md'
import {AiOutlineMail} from 'react-icons/ai'
import {BiPhoneCall} from 'react-icons/bi'
import Header from '../Header/Header'

const Contact = () => {
    return ( 
        <main className='Contact'>
            <Header titleA='CONTACT' titleB='US'/>
            <div className="container flex text-center flex-justify-center">
                <div className='cont flex flex-column gap-1 flex-justify-center flex-align-center'>
                    <HiOutlineHome  style={{fontSize: '35px', color: '#f48915'}}/>
                    <p>VISIT US</p>
                    <p  style={{maxWidth: '35ch'}}>Lorem ipsum dolor sit amet consectetur adipisicing elit. Ullam, optio.</p>
                    <p className='flex gap-0p5 flex-align-center ' style={{color: '#f48915'}}>
                        <MdLocationOn />
                        <motion.p  whileHover={{textDecoration: 'underline'}} className='pointer'>Washington DC United States</motion.p>
                    </p>
                </div>
                <div className='cont flex flex-column gap-1 flex-justify-center flex-align-center'>
                    <AiOutlineMail  style={{fontSize: '35px', color: '#f48915'}}/>
                    <p>CONTACT US</p>
                    <p style={{maxWidth: '35ch'}}>Lorem ipsum dolor sit amet consectetur adipisicing elit. Ullam, optio.</p>
                    <p className='flex gap-0p5 flex-align-center ' style={{color: '#f48915'}}>
                        <motion.p whileHover={{textDecoration: 'underline'}} className='pointer'>BrixtonInvest@gmail.com</motion.p>
                    </p>
                </div>
                <div className='cont flex flex-column gap-1 flex-justify-center flex-align-center'>
                    <BiPhoneCall  style={{fontSize: '35px', color: '#f48915'}}/>
                    <p>CALL US</p>
                    <p  style={{maxWidth: '35ch'}}>Lorem ipsum dolor sit amet consectetur adipisicing elit. Ullam, optio.</p>
                    <p className='flex gap-0p5 flex-align-center ' style={{color: '#f48915'}}>
                        <motion.p whileHover={{textDecoration: 'underline'}} className='pointer'>+44 505 560 2255</motion.p>
                    </p>
                </div>
            </div>
        </main>
     );
}
 
export default Contact;