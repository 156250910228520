import '../../Global.css'
import Header from '../Header/Header';
import Line from '../Line/Line';
import './Expert.scss'

const Expert = () => {
    return ( 
        <main className='Expert'>
            <Header titleA='OUR' titleB='EXPERTS'/>
            <Line />
            <div>
                <div>
                    <div className="circle"></div>
                    <p>Simeone Dylan Perry</p>
                    <p>
                        <p>Lorem ipsum dolor sit amet consectetur, adipisicing</p>
                        <p style={{marginTop: '0.5rem'}}>CEO</p>
                    </p>
                </div>
                <div>
                    <div className="circle"></div>
                    <p>Christian Craig McConor</p>
                    <p>
                        <p>Lorem ipsum dolor sit amet consectetur, adipisicing</p>
                        <p style={{marginTop: '0.5rem'}}>DIRECTOR</p>
                    </p>
                </div>
                <div>
                    <div className="circle"></div>
                    <p>Banks Larry Jackson</p>
                    <p>
                        <p>Lorem ipsum dolor sit amet consectetur, adipisicing</p>
                        <p style={{marginTop: '0.5rem'}}>CONSULTANT</p>
                    </p>
                </div>
            </div>
        </main>
     );
}
 
export default Expert;